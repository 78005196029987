<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">. . .</li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/penerima-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Detail Penerima Barang</span></router-link></li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/detail-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Detail Paket</span></router-link></li>            
            <li class="breadcrumb-item active" aria-current="page">Pengukuran Paket</li>						
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>            
            <div class="col-xl-8 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="card pb-4">
									<div class="brand-logo text-center">
										<img class="pt-5" src="@/assets/images/kebut/XMLID_1147_.png">
									</div>
									<div class="row pl-4">
										<h6 class="font-weight-bold col-lg-12">Berat Maksimal Paket : 25kg</h6>
										<h6 class="font-weight-bold col-lg-12">Batas Ukuran Paket : 60x60x60</h6>
										<h6 class="font-weight-bold col-lg-12">Gratis Waktu Tunggu : 15 Menit</h6>
									</div>																											        
                </div>

                <h4 class="pt-3 text-center">Detail Paket</h4>
                <form class="pt-3">
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Berat Barang</label></b-col>
                        </b-row>
                        <b-input-group>
                          <b-form-input type="number" v-model="form.weight" @change="getWeight"></b-form-input>
                          <template #append>
                            <b-form-select v-model="optSelect" :options="dataOption1" @change="getWeight"></b-form-select>                            
                          </template>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 text-center">
                      <span class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #9E9E9E;text-decoration: underline;">Ukuran / Dimensi Barang</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Panjang</label></b-col>
                        </b-row>
                        <b-input-group size="md" :append="unitDimension">
                          <b-form-input type="number" v-model="form.long" @change="getVolume"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Lebar</label></b-col>
                        </b-row>
                        <b-input-group size="md" :append="unitDimension">
                          <b-form-input type="number" v-model="form.wide" @change="getVolume"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Tinggi</label></b-col>
                        </b-row>
                        <b-input-group size="md" :append="unitDimension">
                          <b-form-input type="number" v-model="form.high" @change="getVolume"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Volume Barang</label></b-col>
                        </b-row>
                        <b-input-group size="md" :append="cubicM">
                          <b-form-input type="number" class="bg-info text-white" v-model="form.volume" :disabled="true"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>                                                            
                  </div>
                  
                  <div class="row">
                    <div class="col-lg-12 text-center">
                      <span class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #9E9E9E;text-decoration: underline;">Waktu Tunggu</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <b-row class="pt-3 pb-3">
                      <b-col lg="6"><h6>Biaya Tunggu</h6></b-col>
                      <b-col lg="6"><h6 class="pull-right" style="color: #e91e63;">Rp. {{ form.price_time }}</h6></b-col>                      
                    </b-row>
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="selected"
                      :options="optionsRadio"
                      name="radio-options"
                      class="col-lg-12"
                      stacked
                      @change="getTimePrice"
                    ></b-form-radio-group>                    
                    <!-- <input type="email" v-model="form.email" class="form-control form-control-lg" placeholder="(contoh patokan)"> -->
                  </div>                                    

                  <div class="mt-3">
                    <template v-if="alert.status != 0">
                      <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                    </template>
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Lanjut</b-button>                    
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      unitDimension : "cm",
      cubicM: "\u33A5",
      errorMassage:0,
      uuid_package_sizes : '',
      form:{
        route: '',
        route_title: 'pengukuran_paket',        
        uuid_units_of_measurements : '',
        weight : 0,
        long : 0,
        wide : 0,
        high : 0,
        volume : 0,
        hour: 0,
        minute: 0,
        price_dimension: 0,
        price_weight: 0,
        price_time: 0,
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataTime: [],
      dataOption1: [],
      optSelect: '',
      optionsRadio: [],
      selected: '', 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),    
    getVolume: function() {
      let dataVolume = {
        long : this.form.long,
        wide : this.form.wide,
        high : this.form.high,
        uuid_package_sizes: this.uuid_package_sizes
      }

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
      axios.post(`${packageStore.mainApi}/api/web/operational/antar-barang/count-dimensions`,dataVolume)
      .then((response) => {
        this.form.volume = response.data.m3
        this.form.price_dimension = response.data.result_price
        console.log(response.data);        
      })      
    },
    getWeight: function() {
      let dataWeight = {
        weight : this.form.weight,
        uuid_units_of_measurements : this.optSelect,
        uuid_package_sizes: this.uuid_package_sizes
      }

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
      axios.post(`${packageStore.mainApi}/api/web/operational/antar-barang/count-weight`,dataWeight)
      .then((response) => {
        console.log(response.data);
        // this.form.volume = response.data.m3
        this.form.price_weight = response.data.result_price
      })      
    },    
    getTimePrice: function() {
      let id = this.selected
      this.dataTime.forEach(element => {
        if (element.id == id) {
          this.form.hour = element.hour
          this.form.minute = element.minute
          this.form.price_time = element.priceTime          
        }
      });
    },
    getLoadTime: function () {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/load-time/byActive`,packageGlobals.configAxios)
			.then((response) => {
        let opt = response.data.data
        this.dataTime = response.data.data
        opt.forEach(element => {
          let pushData = {value : element.id,text : element.string}
          this.optionsRadio.push(pushData)
        });
			})	
    },    
    getWeightIndicator: function () {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
      axios.get(`${packageStore.mainApi}/api/web/reference/units-of-measurement/byType/tuQpZRqQWzlvGnh`,packageGlobals.configAxios)
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            value : element.uuid,
            text : element.name
          }
          this.dataOption1.push(pushData)
          this.optSelect = 'sqSj6F4wUUWuZqd'
        });
			})							
    },            
    getData: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.uuid_package_sizes = response.data.result.uuid_package_sizes
        this.form.route = '/operasional/antar-barang/pengukuran-paket/'+this.params.uuid        
        this.form.uuid_units_of_measurements = response.data.result.uuid_units_of_measurements
        this.form.weight = response.data.result.weight
        this.form.long = response.data.result.long
        this.form.wide = response.data.result.wide
        this.form.high = response.data.result.high
        this.form.volume = response.data.result.volume
        this.form.price_dimension = response.data.result.price_dimension
        this.form.price_weight = response.data.result.price_weight                        
			})      
    },      
    // onNext(evt) {
    onNext() {      
      this.form.uuid_units_of_measurements = this.optSelect
      console.log(this.form);
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/pengiriman-paket/${this.params.uuid}`)
      })      
    }
  },
  mounted() {
    this.getData(this.params.uuid)    
		this.getWeightIndicator()
    this.getLoadTime()
    // this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}

.input-group-text{
  font-weight: bold;
}

.auth .auth-form-light select
{
  color:black;
}
</style>